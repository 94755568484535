/*-----------------------------------------------
|   Navigation bar
-----------------------------------------------*/
.navbar {
  .dropdown-menu {
    overflow: visible;
    left: -0.5625rem;
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      width: 1rem;
      height: 1rem;
      background: $black;
      top: -0.375rem;
      left: map_get($spacers, 4);
      transform: rotate(45deg);
      background: inherit;
      border-radius: 0.125rem;
      border-width: 1px 0 0 1px;
      border-style: solid;
      border-color: $dropdown-border-color;
      box-shadow: $box-shadow-sm;
    }
    &.dropdown-menu-right {
      left: auto;
      right: -0.5625rem;
      &:after {
        right: 0.95rem;
        left: auto;
      }
    }
  }

  // Initial hover color for all navbar dropdown item
  .dropdown-item {
    font-weight: $font-weight-medium;
    @include hover-focus {
      background-color: initial;
      color: $gray-1000;
    }
  }
}
.navbar-glass {
  background-color: rgba($body-bg, 0.96);
}
.safari {
  .navbar-glass {
    // Issue after safari update
    // background-color: rgba($body-bg, 0.82);
    // backdrop-filter: blur(5px);
    box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0);
  }
}
.navbar-brand {
  font-weight: $navbar-brand-font-weight;
}
.navbar-glass-shadow {
  box-shadow: 0 0.5rem 0.5rem -0.5rem rgba($black, 0.2) !important;
}
.navbar-toggler-humburger-icon {
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  @include hover-focus {
    background-color: $gray-300;
  }
}
.navbar-toggle-icon {
  position: relative;
  height: 0.125rem;
  width: 1.25rem;
  transition: $transition-base;
  display: block;
  top: -0.3125rem;
  padding-right: 0;
  .toggle-line {
    display: block;
    height: 100%;
    border-radius: $border-radius;
    transition: $transition-base;
    background-color: $gray-700;
  }
  &:after,
  &:before {
    content: "";
    position: absolute;
    height: 0.125rem;
    background-color: $gray-700;
    left: 0;
    transition: $transition-base;
    border-radius: $border-radius;
    width: 100%;
  }
  &:before {
    top: 0.3125rem;
  }
  &:after {
    top: 0.625rem;
  }
}

.windows {
  &.chrome {
    .navbar-top {
      .navbar-collapse {
        @include chrome-scrollbar;
      }
    }
  }

  &.firefox {
    .navbar-top {
      .navbar-collapse {
        @include firefox-scrollbar;
      }
    }
  }
}
